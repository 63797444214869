import React, { FC } from 'react';

import { AppLayoutBreakpoint, useContainerWidth } from '../../../../util/responsiveness';
import { FlexItem } from '../../../Flex';
import AppLayoutRow from '../../AppLayoutRow';
import { useContentContainerContext } from '../../hooks';

import { SubHeaderRowProps } from './SubHeaderRow.types';

const subHeaderHeight = 50;

const SubHeaderRow: FC<SubHeaderRowProps> = ({ children, forceVisibleAtAllBreakpoints }) => {
  const { shouldShowSubHeaderAtAllBreakpoints } = useContentContainerContext();

  const width = useContainerWidth();
  const shouldShowInCurrentWidth = shouldShowSubheaderAtWidth(width);
  const shouldRender =
    children && (shouldShowInCurrentWidth || forceVisibleAtAllBreakpoints || shouldShowSubHeaderAtAllBreakpoints);

  if (!shouldRender) {
    return null;
  }

  return (
    <AppLayoutRow height={subHeaderHeight} zIndex={3}>
      <FlexItem grow={1} height={subHeaderHeight}>
        {children}
      </FlexItem>
    </AppLayoutRow>
  );
};

export default SubHeaderRow;

export const shouldShowSubheaderAtWidth = (width: number) => width < AppLayoutBreakpoint.Width812;
