import { dangerouslySetInnerHTML } from '@ms/yammer-content-support';
import { staticFluentColors } from '@ms/yammer-web-core-ui/dist/util/colors';

const reduceStyle = (style: Record<string, number | string>) =>
  Object.keys(style).reduce((acc, key) => `${key}: ${style[key]}; ${acc}`, '');

const commonStyles = {
  display: 'flex',
  'flex-direction': 'column',
  'align-items': 'center',
  'justify-content': 'center',
};

const getContainerStyle = (isInverted: boolean) =>
  reduceStyle({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    'background-color': isInverted ? staticFluentColors.black : staticFluentColors.gray10,
    ...commonStyles,
  });

const spittleStyle = reduceStyle({
  flex: '1 1 100%',
  ...commonStyles,
});

const microsoftStyle = reduceStyle({
  'margin-bottom': '36px',
  ...commonStyles,
});

const teamsSpinnerStyle = reduceStyle({
  'box-sizing': 'border-box',
  'border-radius': '50%',
  'border-width': '1.5px',
  'border-style': 'solid',
  'border-color': '#6264A7 #BDBDE6 #BDBDE6 #BDBDE6',
  'border-image': 'initial',
  'animation-name': 'teams-spinner-animation',
  'animation-duration': '1.3s',
  'animation-iteration-count': 'infinite',
  'animation-timing-function': 'cubic-bezier(0.53, 0.21, 0.29, 0.67)',
  width: '28px',
  height: '28px',
});

const { default: microsoftLogo } = require('!raw-loader!@ms/yammer-assets/dist/images/microsoft.svg');
const { default: spittleLogo } = require('!raw-loader!@ms/yammer-assets/dist/images/spittle.svg');

const insertSplashLogos = (parentNode: HTMLElement, isInverted: boolean) => {
  dangerouslySetInnerHTML(
    parentNode,
    `
  <div aria-hidden="true" style="${getContainerStyle(isInverted)}">
    <div style="${spittleStyle}">${spittleLogo}</div>
    <div style="${microsoftStyle}">${microsoftLogo}</div>
  </div>
  `
  );
};

const addTeamsSpinnerAnimation = () => {
  const styleSheet = document.createElement('style');
  document.head.appendChild(styleSheet);

  styleSheet?.sheet?.insertRule(
    `@keyframes teams-spinner-animation {
      0% {
        transform: rotate(0deg);
      }
    
      100% {
        transform: rotate(360deg);
      }
    }`,
    0
  );
};

const insertTeamsSpinner = (parentNode: HTMLElement, isInverted: boolean) => {
  addTeamsSpinnerAnimation();

  dangerouslySetInnerHTML(
    parentNode,
    `
  <div aria-hidden="true" style="${getContainerStyle(isInverted)}">
    <div style="${teamsSpinnerStyle}"></div>
  </div>
  `
  );
};

export interface InsertSplashOptions {
  readonly target: HTMLElement | null;
  readonly denylist?: RegExp;
  readonly isInverted?: boolean;
  readonly shouldUseTeamsSpinner?: boolean;
}

export type InsertSplash = (options: InsertSplashOptions) => void;
export const insertSplash: InsertSplash = ({ target, denylist, isInverted = false, shouldUseTeamsSpinner = false }) => {
  const { pathname, href } = window.location;
  const isPathnameBlocked = denylist && denylist.test(pathname);
  const isQueryParamOverride = /(\?|&)splash=0/g.test(href);
  if (target && !target.hasChildNodes() && !isPathnameBlocked && !isQueryParamOverride) {
    if (shouldUseTeamsSpinner) {
      insertTeamsSpinner(target, isInverted);
    } else {
      insertSplashLogos(target, isInverted);
    }
  }
};
