import { error as logErrorToConsole } from '@ms/yammer-console-logging';
import { AnalyticsV2Event } from '@ms/yammer-telemetry';
import { getTelemetryClientConfig } from '@ms/yammer-telemetry-store';

import {
  AnalyticsV2EventInput,
  LogEventInput,
  ReportAnalyticsEventsInput,
  ReportLogEventsInput,
} from './eventInputTypes';
import { loadProtobufRequestFunctions } from './protobuf/lazyExports';
import { getEventHeadersWithAuthentication, getEventHeadersWithoutAuthentication } from './reportHeaders';

const UnknownClientId = 'UNKNOWN';
const LogEventApiPath = '/api/v1/yamalytics/webui';
const AnalyticsEventApiPath = '/api/v2/events';
const AnalyticsV2EventApiPath = '/api/v3/events';

const createRequestOptions = (headers: Headers, events: LogEventInput[] | ReportAnalyticsEventsInput) => ({
  headers,
  method: 'POST',
  body: JSON.stringify(events),
});

const createAnalyticsV2RequestOptions = async (headers: Headers, events: AnalyticsV2Event[]) => {
  const { getProtobufEventRequestBody } = await loadProtobufRequestFunctions();

  return {
    headers,
    method: 'POST',
    body: getProtobufEventRequestBody(events),
  };
};

const createUrl = (host: string, path: string) => `${host}${path}`;

const createHeaders = (headers: Record<string, string>) =>
  new Headers({
    ...headers,
    accept: 'application/json',
    'content-type': 'application/json',
  });

const handleFetchResponse = async (response: Response) => {
  if (!response.ok) {
    throw new Error(
      `Fetch failed for ${response.url} with status ${response.statusText} with details ${await response.text()}`
    );
  }
};

const executeFetchRequest = async (url: string, requestOptions: RequestInit) => {
  const response = await fetch(url, requestOptions);
  await handleFetchResponse(response);
};

type ReportTelemetryEventsToServer = (requestOptions: RequestInit, apiPath: string) => Promise<void>;
const reportTelemetryEventsToServer: ReportTelemetryEventsToServer = async (requestOptions, apiPath) => {
  const { telemetryHost } = getTelemetryClientConfig();
  if (!telemetryHost) {
    return;
  }

  const url = createUrl(telemetryHost, apiPath);
  await executeFetchRequest(url, requestOptions);
};

type ReportLogEventsNow = (logEventsInput: ReportLogEventsInput) => Promise<void>;
export const reportLogEventsNow: ReportLogEventsNow = async (logEventsInput) =>
  reportTelemetryEventsNow({
    events: [...logEventsInput.errorEvents, ...logEventsInput.performanceEvents, ...logEventsInput.infoEvents],
    eventInputType: 'Log',
  });

type ReportAnalyticsEventsNow = (analyticsEventsInput: ReportAnalyticsEventsInput) => Promise<void>;
export const reportAnalyticsEventsNow: ReportAnalyticsEventsNow = (analyticsEventsInput) =>
  reportTelemetryEventsNow({ events: analyticsEventsInput, eventInputType: 'Analytics' });

type ReportAnalyticsV2EventsNow = (analyticsEventsInput: AnalyticsV2EventInput[]) => Promise<void>;
export const reportAnalyticsV2EventsNow: ReportAnalyticsV2EventsNow = (analyticsEventsInput) =>
  reportTelemetryEventsNow({ events: analyticsEventsInput, eventInputType: 'AnalyticsV2' });

const groupLogEventsByProperty = (events: LogEventInput[], property: string) =>
  events.reduce<Record<string, LogEventInput[]>>(
    (acc, event) => {
      const value = `${Boolean(event.Parameters[property])}`;
      acc[value].push(event);

      return acc;
    },
    { false: [], true: [] }
  );

const reportLogEventsToServer = async (events: LogEventInput[]) => {
  const groupedEvents: Record<string, LogEventInput[]> = groupLogEventsByProperty(
    events as LogEventInput[],
    'skipAuthentication'
  );

  const eventsWithAuthentication = groupedEvents.false;
  const eventsWithoutAuthentication = groupedEvents.true;

  const reportCalls = [];
  if (eventsWithAuthentication.length > 0) {
    const fetchHeaders = createHeaders(await getEventHeadersWithAuthentication());
    const requestOptions = createRequestOptions(fetchHeaders, eventsWithAuthentication);

    reportCalls.push(reportTelemetryEventsToServer(requestOptions, LogEventApiPath));
  }

  if (eventsWithoutAuthentication.length > 0) {
    const fetchHeaders = createHeaders(await getEventHeadersWithoutAuthentication());
    const requestOptions = createRequestOptions(fetchHeaders, eventsWithoutAuthentication);

    reportCalls.push(reportTelemetryEventsToServer(requestOptions, LogEventApiPath));
  }

  await Promise.all(reportCalls);
};

interface ReportAnalyticsEventsNowProps {
  readonly eventInputType: 'Analytics';
  readonly events: ReportAnalyticsEventsInput;
}

interface ReportAnalyticsV2EventsNowProps {
  readonly eventInputType: 'AnalyticsV2';
  readonly events: AnalyticsV2EventInput[];
}

interface ReportLogEventsNowProps {
  readonly eventInputType: 'Log';
  readonly events: LogEventInput[];
}

type ReportTelemetryEventsNowProps =
  | ReportAnalyticsEventsNowProps
  | ReportAnalyticsV2EventsNowProps
  | ReportLogEventsNowProps;

type ReportTelemetryEventsNow = (props: ReportTelemetryEventsNowProps) => Promise<void>;
const reportTelemetryEventsNow: ReportTelemetryEventsNow = async (props) => {
  const { telemetryClientId } = getTelemetryClientConfig();

  if (telemetryClientId === UnknownClientId) {
    logErrorToConsole('Unexpected telemetryClientId = UNKNOWN');

    return;
  }

  try {
    if (props.eventInputType === 'Analytics') {
      const fetchHeaders = createHeaders(await getEventHeadersWithAuthentication());
      const requestOptions = createRequestOptions(fetchHeaders, props.events);

      await reportTelemetryEventsToServer(requestOptions, AnalyticsEventApiPath);
    } else if (props.eventInputType === 'AnalyticsV2') {
      const fetchHeaders = createHeaders(await getEventHeadersWithAuthentication());
      const requestOptions = await createAnalyticsV2RequestOptions(fetchHeaders, props.events);

      await reportTelemetryEventsToServer(requestOptions, AnalyticsV2EventApiPath);
    } else {
      await reportLogEventsToServer(props.events);
    }
  } catch (err) {
    logErrorToConsole(`Telemetry reporting failed. Details: ${JSON.stringify(err, Object.getOwnPropertyNames(err))}`);
  }
};
