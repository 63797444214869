import { isEventsPage } from '@ms/yammer-web-support/dist/location';

import { useExperimentTreatmentWithoutReporting } from '../experiments';

type UseIsLiveEventPage = () => boolean;
export const useIsLiveEventPage: UseIsLiveEventPage = () => {
  const isLiveEventsScaleLoadPerfEnabled = useExperimentTreatmentWithoutReporting('LiveEventsScaleLoadPerf') === true;
  const isLiveEventsPage = isEventsPage(window.location.pathname);

  return isLiveEventsScaleLoadPerfEnabled && isLiveEventsPage;
};
