import { UseMainUIFeatureSettings } from '../types';

import { useAchievementsAdminPageSettings } from './achievement';
import { useAdminMuteUserAdminPageSettings } from './adminMuteUser';
import { useArticlePageSettings, useArticlePublisherSettings } from './article';
import { useAskMeAnythingEventCreationPageSettings, useAskMeAnythingEventEditingPageSettings } from './askMeAnything';
import { useCampaignsDashboardPageSettings } from './campaign';
import {
  useCommsDashboardDigitalSafetyPageSettings,
  useCommsDashboardFeaturedConversationPageSettings,
  useCommsDashboardLeadersAndAudiencesPageSettings,
  useCommsDashboardPageSettings,
} from './communicationsDashboard';
import { useHomeFeedPageSettings, useThreadPageSettings } from './feed';
import { useDiscoverGroupsPageSettings } from './group';
import { useInboxPageSettings } from './inbox';
import { useInformationBarrierSettingsAdminPage } from './informationBarrier';
import {
  useInsightsAdminPageSettings,
  useInsightsCampaignPageSettings,
  useInsightsGroupPageSettings,
  useInsightsLeadersMyInsightsPageSettings,
  useInsightsLeadersOrgPageSettings,
  useInsightsNavigationSettings,
  useInsightsNetworkPageSettings,
  useInsightsPremiumGroupPageSettings,
  useInsightsQnAMyInsightsPageSettings,
  useInsightsQnAOrgPageSettings,
} from './insights';
import { useLeadersAdminPageSettings, useLeadershipCornerAdminPageSettings } from './leader';
import { useConfigureTenantAdminPageSettings, useNetworkAdminPageSettings } from './legacy';
import { useComplianceDataAdminPageSettings } from './manageData';
import { useManageUsersAdminPageSettings } from './manageUsers';
import { useMTOPolicyAdminPageSettings } from './multiTenantOrganization';
import { useNavigationSettings } from './navigation';
import { useNetworkThemeFeedPageSettings } from './networkTheme';
import { useQuestionsAdminPageSettings } from './question';
import { useStorylineAdminPageSettings } from './storyline';
import { useTownhallCreateEventPageSettings, useTownhallEditEventPageSettings } from './townhallEvent';
import { useStrictFeatureSettings } from './useStrictFeatureSettings';
import { useUserRolesAdminPageSettings } from './user';
import { useViewOnlyModeAdminPageSettings } from './viewOnlyMode';

export const useMainFeatureSettings: UseMainUIFeatureSettings = (...args) => {
  const achievementsAdminPage = useAchievementsAdminPageSettings(...args);
  const articlePage = useArticlePageSettings(...args);
  const articlePublisher = useArticlePublisherSettings(...args);
  const askMeAnythingEventCreationPage = useAskMeAnythingEventCreationPageSettings(...args);
  const askMeAnythingEventEditingPage = useAskMeAnythingEventEditingPageSettings(...args);
  const campaignsDashboardPage = useCampaignsDashboardPageSettings(...args);
  const complianceDataAdminPage = useComplianceDataAdminPageSettings(...args);
  const communicationsDashboardPage = useCommsDashboardPageSettings(...args);
  const communicationsDashboardFeaturedConversationsPage = useCommsDashboardFeaturedConversationPageSettings(...args);
  const communicationsDashboardDigitalSafetyPage = useCommsDashboardDigitalSafetyPageSettings(...args);
  const communicationsDashboardLeadersAndAudiencesPage = useCommsDashboardLeadersAndAudiencesPageSettings(...args);
  const configureTenantAdminPage = useConfigureTenantAdminPageSettings(...args);
  const discoverGroupsPage = useDiscoverGroupsPageSettings(...args);
  const homeFeedPage = useHomeFeedPageSettings(...args);
  const inboxPage = useInboxPageSettings(...args);
  const informationBarrierSettingsAdminPage = useInformationBarrierSettingsAdminPage(...args);
  const insightsAdminPage = useInsightsAdminPageSettings(...args);
  const insightsCampaignPage = useInsightsCampaignPageSettings(...args);
  const insightsGroupPage = useInsightsGroupPageSettings(...args);
  const insightsLeadersMyInsightsPage = useInsightsLeadersMyInsightsPageSettings(...args);
  const insightsLeadersOrgPage = useInsightsLeadersOrgPageSettings(...args);
  const insightsNavigation = useInsightsNavigationSettings(...args);
  const insightsNetworkPage = useInsightsNetworkPageSettings(...args);
  const insightsPremiumGroupPage = useInsightsPremiumGroupPageSettings(...args);
  const insightsQnAMyInsightsPage = useInsightsQnAMyInsightsPageSettings(...args);
  const insightsQnAOrgPage = useInsightsQnAOrgPageSettings(...args);
  const leadersAdminPage = useLeadersAdminPageSettings(...args);
  const leadershipCornerAdminPage = useLeadershipCornerAdminPageSettings(...args);
  const mtoPolicyAdminPage = useMTOPolicyAdminPageSettings(...args);
  const navigation = useNavigationSettings(...args);
  const networkAdminPage = useNetworkAdminPageSettings(...args);
  const networkThemeFeedPage = useNetworkThemeFeedPageSettings(...args);
  const questionsAdminPage = useQuestionsAdminPageSettings(...args);
  const storylineAdminPage = useStorylineAdminPageSettings(...args);
  const threadPage = useThreadPageSettings(...args);
  const townhallCreateEventPage = useTownhallCreateEventPageSettings(...args);
  const townhallEditEventPage = useTownhallEditEventPageSettings(...args);
  const userRolesAdminPage = useUserRolesAdminPageSettings(...args);
  const viewOnlyModeAdminPage = useViewOnlyModeAdminPageSettings(...args);
  const manageUsersAdminPage = useManageUsersAdminPageSettings(...args);
  const adminMuteUserAdminPage = useAdminMuteUserAdminPageSettings(...args);

  return useStrictFeatureSettings({
    achievementsAdminPage,
    articlePage,
    articlePublisher,
    askMeAnythingEventCreationPage,
    askMeAnythingEventEditingPage,
    campaignsDashboardPage,
    complianceDataAdminPage,
    communicationsDashboardPage,
    communicationsDashboardDigitalSafetyPage,
    communicationsDashboardLeadersAndAudiencesPage,
    communicationsDashboardFeaturedConversationsPage,
    configureTenantAdminPage,
    discoverGroupsPage,
    homeFeedPage,
    inboxPage,
    informationBarrierSettingsAdminPage,
    insightsAdminPage,
    insightsCampaignPage,
    insightsGroupPage,
    insightsLeadersMyInsightsPage,
    insightsLeadersOrgPage,
    insightsNavigation,
    insightsNetworkPage,
    insightsPremiumGroupPage,
    insightsQnAMyInsightsPage,
    insightsQnAOrgPage,
    leadersAdminPage,
    leadershipCornerAdminPage,
    mtoPolicyAdminPage,
    navigation,
    networkAdminPage,
    networkThemeFeedPage,
    questionsAdminPage,
    storylineAdminPage,
    threadPage,
    townhallCreateEventPage,
    townhallEditEventPage,
    userRolesAdminPage,
    viewOnlyModeAdminPage,
    manageUsersAdminPage,
    adminMuteUserAdminPage,
  });
};
