import queryCallToAction from '@ms/yammer-graphql/dist/operations/queryViewerCallToAction.graphql';

import {
  ManagedApiLifecycle,
  getGraphqlApiRequest,
  getTreatmentsExtensionForGraphqlBody,
  managedApiAction,
} from '../..';
import { SuggestedActionSurface } from '../../state/types';
import { reportError } from '../../telemetry';
import { PromiseThunkAction } from '../../types';

import { SuggestedActionsResponse } from './actions.types';

const maxSuggestedActions = 6;

type LoadSuggestedActions = (
  lifecycle: ManagedApiLifecycle<SuggestedActionsResponse>,
  surface: SuggestedActionSurface
) => PromiseThunkAction<void>;
export const loadSuggestedActions: LoadSuggestedActions = (lifecycle, surface) => async (dispatch, getState) => {
  try {
    const state = getState();

    const treatments = getTreatmentsExtensionForGraphqlBody({
      state,
      experiments: ['LightboxFollowUsers'],
      featureRollouts: ['SuggestedActionFollowUsersIgnoreCheck', 'SuggestedActionIgnoreMaxJoinedGroups'],
    });

    const query = {
      body: {
        operationName: 'ViewerCallToActionClients',
        query: queryCallToAction,
        variables: {
          surface,
          count: maxSuggestedActions,
          context: {
            feedType: 'HOME',
            feedSubtype: 'ALL',
            contextIds: [],
          },
        },
      },
      treatments,
    };

    const request = getGraphqlApiRequest(query);

    const action = managedApiAction({
      request,
      lifecycle,
    });

    await dispatch(action);
  } catch (error) {
    reportError({
      error,
    });
  }
};
