import { useIsFeatureRolloutEnabled } from '../featureRollouts';
import { useCurrentUserFieldThrows } from '../users';

type UseCanCurrentUserAccessEnhancedUserProfile = () => boolean;
export const useCanCurrentUserAccessEnhancedUserProfile: UseCanCurrentUserAccessEnhancedUserProfile = () => {
  const isEnhancedUserProfileEnabled = useIsFeatureRolloutEnabled('EnhancedUserProfiles');
  const isCurrentUserGuest = useCurrentUserFieldThrows('isGuest');

  return isEnhancedUserProfileEnabled && !isCurrentUserGuest;
};
