import { IFontStyles, IRawStyle, ITheme } from '../styling';

const defaultFontSizeInPixels = 16;

export const pxToRawRemNumber = (pixels: number): number => pixels / defaultFontSizeInPixels;

export const pxToRemString = (pixels: number): string => `${pxToRawRemNumber(pixels)}rem`;

export type TextAlign = 'left' | 'right' | 'center';
export type TextDirection = 'auto' | 'ltr' | 'rtl';

export type TextDecoration = 'underline' | 'line-through';

export type FontStyle = 'normal' | 'italic';

export type TextTransform = 'capitalize' | 'lowercase' | 'uppercase';

export type TextColor =
  /**
   * Primary black text color
   */
  | 'primary'

  /**
   * Secondary dark gray text color
   */
  | 'secondary'

  /**
   * Accent color for mentions, topics, etc.
   */
  | 'accent'

  /**
   * Tertiary color for metadata
   */
  | 'metadata'

  /*
   * For use on darker backgrounds.
   */
  | 'white'

  /**
   * For use on darker backgrounds. Note: This will always be white, even in dark mode.
   */
  | 'staticWhite'

  /**
   * For use in error messages.
   */
  | 'error'

  /**
   * For use in interaction elements which have become disabled.
   */
  | 'disabled';

/**
 * @deprecated Please use numeric font sizes on Text or Block components, or Fluent9 styling with tokens.
 */
export type TextSize =
  /**
   * X-Large headers. Font-size 48px, line-height 46px.
   * @deprecated Please use numeric font size 900
   */
  | 'xLarge'

  /**
   * Large headers. Font-size 24px, line-height 32px.
   * @deprecated Please use numeric font size 600
   */
  | 'large'

  /**
   * Medium plus headers. Font-size 18px, line-height 24px.
   * @deprecated Please use numeric font size 400
   */
  | 'mediumPlus'

  /**
   * Body text, default size. Font-size 16px, line-height 22px.
   * @deprecated Please use numeric font size 400
   */
  | 'medium'

  /**
   * Secondary text. Font-size 14px, line-height 20px.
   * @deprecated Please use numeric font size 300
   */
  | 'mediumSub'

  /**
   * Metadata. Font-size 12px, line-height 16px.
   * @deprecated Please use numeric font size 200
   */
  | 'small'

  /**
   * Extra small headings or badges. Font-size 10px, line-height 14px.
   * @deprecated Please use numeric font size 100
   */
  | 'xSmall';

export interface FontSizeDimension {
  readonly fontSize: string;
  readonly lineHeight: string;
}

interface FontSizeDimensionInRems {
  readonly fontSize: number;
  readonly lineHeight: number;
}

export type ThemeFontSizeName = keyof IFontStyles;

type ThemeFontSizeDimensionsInRems = Record<ThemeFontSizeName, FontSizeDimensionInRems>;
const baseThemeFontSizeDimensionsInRems: ThemeFontSizeDimensionsInRems = {
  tiny: { fontSize: pxToRawRemNumber(10), lineHeight: pxToRawRemNumber(14) },
  xSmall: { fontSize: pxToRawRemNumber(10), lineHeight: pxToRawRemNumber(14) },
  small: { fontSize: pxToRawRemNumber(12), lineHeight: pxToRawRemNumber(16) },
  smallPlus: { fontSize: pxToRawRemNumber(12), lineHeight: pxToRawRemNumber(16) },
  medium: { fontSize: pxToRawRemNumber(14), lineHeight: pxToRawRemNumber(20) },
  mediumPlus: { fontSize: pxToRawRemNumber(16), lineHeight: pxToRawRemNumber(22) },
  large: { fontSize: pxToRawRemNumber(16), lineHeight: pxToRawRemNumber(22) },
  xLarge: { fontSize: pxToRawRemNumber(20), lineHeight: pxToRawRemNumber(28) },
  xLargePlus: { fontSize: pxToRawRemNumber(24), lineHeight: pxToRawRemNumber(32) },
  xxLarge: { fontSize: pxToRawRemNumber(28), lineHeight: pxToRawRemNumber(36) },
  xxLargePlus: { fontSize: pxToRawRemNumber(32), lineHeight: pxToRawRemNumber(42) },
  superLarge: { fontSize: pxToRawRemNumber(40), lineHeight: pxToRawRemNumber(52) },
  mega: { fontSize: pxToRawRemNumber(68), lineHeight: pxToRawRemNumber(90) },
};

const textSizeToFontSizeMap: Record<TextSize, ThemeFontSizeName> = {
  xSmall: 'xSmall',
  small: 'small',
  mediumSub: 'medium',
  medium: 'mediumPlus',
  mediumPlus: 'large',
  large: 'xLargePlus',
  xLarge: 'superLarge',
};

const textSizes: TextSize[] = ['xSmall', 'small', 'mediumSub', 'medium', 'mediumPlus', 'large', 'xLarge'];
type FontSizeDimensionsInRems = Record<TextSize, FontSizeDimensionInRems>;
const baseTextSizeDimensionsInRems: FontSizeDimensionsInRems = textSizes.reduce(
  (accDimensions: FontSizeDimensionsInRems, textSize: TextSize) => ({
    ...accDimensions,
    [textSize]: baseThemeFontSizeDimensionsInRems[textSizeToFontSizeMap[textSize]],
  }),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as FontSizeDimensionsInRems
);

export const getBaseFontSizeStyles: (size: TextSize) => FontSizeStyle = (size) => ({
  fontSize: `${baseTextSizeDimensionsInRems[size].fontSize}rem`,
  lineHeight: `${baseTextSizeDimensionsInRems[size].lineHeight}rem`,
});

type ThemeFontSizeStyles = Record<ThemeFontSizeName, FontSizeDimension>;
export const getBaseThemeFontSizeStyles: () => ThemeFontSizeStyles = () =>
  Object.entries(baseThemeFontSizeDimensionsInRems).reduce(
    (fontStyles: ThemeFontSizeStyles, [key, value]) => ({
      ...fontStyles,
      [key]: { fontSize: `${value.fontSize}rem`, lineHeight: `${value.lineHeight}rem` },
    }),
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as ThemeFontSizeStyles
  );

export interface FontSizeStyle {
  readonly fontSize: string | number;
  readonly lineHeight: string | number;
}

export const getThemeFontSizeStyles: (size: TextSize, theme: ITheme) => FontSizeStyle = (size, theme) => {
  const { fontSize, lineHeight } = theme.fonts[textSizeToFontSizeMap[size]];

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return { fontSize, lineHeight } as FontSizeStyle;
};

export const textColors: (theme: ITheme) => Record<TextColor, string> = (theme) => ({
  primary: theme.semanticColors.bodyText,
  secondary: theme.palette.neutralSecondary,
  accent: theme.palette.accent,
  metadata: theme.semanticColors.bodySubtext,
  white: theme.palette.white,
  staticWhite: theme.isInverted ? theme.palette.black : theme.palette.white,
  error: theme.semanticColors.errorText,
  disabled: theme.semanticColors.disabledText,
});

export type TextWrapStyle =
  /**
   * Limits text to a single line and hides overflow with an ellpsis character.
   */
  | 'ellipsis'

  /**
   * Limits text to multiple lines and hides overflow with an ellpsis character.
   */
  | 'multiLineEllipsis'

  /**
   * Breaks long words onto the next line to prevent text from pushing out of the containing element.
   */
  | 'breakWords'

  /**
   * Preserves text formatting like a <pre> tag, but wraps long lines of text.
   */
  | 'preserveTextFormatting'

  /**
   * Preserves text formatting like a <pre> tag, but wraps long lines of text, and breaks long words when necessary.
   */
  | 'breakWordsAndPreserveTextFormatting'

  /**
   * Prevents text from wrapping. This has the potential to push long text outside of the Block's boundaries, so
   * this should only be used where a very short string needs to remain on one line.
   */
  | 'preventWrap';

export const ellipsisStyles: IRawStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const breakWordStyles: IRawStyle = {
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
};

export const preserveTextFormattingStyles: IRawStyle = {
  whiteSpace: 'pre-wrap',
};

export const preventWrapStyles: IRawStyle = {
  whiteSpace: 'nowrap',
};

export type GetTextWrapStyles = (maxLines: number) => IRawStyle;
export const getMultiLineEllipsisStyles: GetTextWrapStyles = (maxLines) => ({
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': `${maxLines}`,
});
