export const userSettingsPageRoute = '/user-settings';
export const userSettingsNotificationsPageRoute = '/user-settings/notifications';
export const userSettingsNotificationsCommunitiesPageRoute = '/user-settings/notifications/communities';

export const userSettingsNotificationsSubscribePageRoute = '/user-settings/notifications/subscribe';
export const userSettingsNotificationsUnsubscribePageRoute = '/user-settings/notifications/unsubscribe';
export const userSettingsNotificationsUnsubscribeThreadPageRoute = '/user-settings/notifications/unsubscribe/thread';
export const userSettingsNotificationsUnsubscribeStorylinePageRoute =
  '/user-settings/notifications/unsubscribe/storyline';
export const userSettingsNotificationsUnsubscribeInvitationsPageRoute =
  '/user-settings/notifications/unsubscribe/invitations';

export const userSettingsRoutes = [
  userSettingsPageRoute,
  userSettingsNotificationsPageRoute,
  userSettingsNotificationsCommunitiesPageRoute,
  userSettingsNotificationsSubscribePageRoute,
  userSettingsNotificationsUnsubscribePageRoute,
  userSettingsNotificationsUnsubscribeThreadPageRoute,
  userSettingsNotificationsUnsubscribeStorylinePageRoute,
  userSettingsNotificationsUnsubscribeInvitationsPageRoute,
];
