import { dangerouslySetInnerHTMLPolicy } from './dangerouslySetInnerHTMLPolicy';

/**
 * THIS IS DANGEROUS.
 *
 * Setting innerHTML without properly sanitizing it can lead to XSS security vulnerabilities.
 * Only use this function if you know for certain the string is safe to use.
 */
export const dangerouslySetInnerHTML = (node: Element, html: string) => {
  // eslint-disable-next-line no-unsanitized/property
  node.innerHTML = dangerouslySetInnerHTMLPolicy.createHTML(html);
};
