import { useMemo } from 'react';

import { useIsInformationBarrierSettingsEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useInformationBarrierSettingsAdminPage: UseMainUIFeatureSettings<'informationBarrierSettingsAdminPage'> = (
  options
) => {
  const informationBarrierAdminSettingsPageSettingsOverrides = options?.overrides?.informationBarrierSettingsAdminPage;

  const informationBarrierAdminSettingsPageSettingsPolicy = useIsInformationBarrierSettingsEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: informationBarrierAdminSettingsPageSettingsPolicy,
      ...informationBarrierAdminSettingsPageSettingsOverrides,
    }),
    [informationBarrierAdminSettingsPageSettingsOverrides, informationBarrierAdminSettingsPageSettingsPolicy]
  );
};
