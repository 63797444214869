import { loadDismissablePrompts } from '@ms/yammer-data/dist/domains/dismissablePrompts/actions';
import { DismissablePromptKey, DismissablePromptType } from '@ms/yammer-data/dist/state/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useIsLiveEventPage } from '../liveEvents';

export type UseLoadDismissablePrompts = ({
  promptKeys,
  promptTypes,
}: {
  readonly promptKeys?: DismissablePromptKey[];
  readonly promptTypes?: DismissablePromptType[];
}) => void;
export const useLoadDismissablePrompts: UseLoadDismissablePrompts = ({ promptKeys = [], promptTypes = [] }) => {
  const dispatch = useDispatch();
  const isLiveEventPage = useIsLiveEventPage();

  useEffect(() => {
    if (isLiveEventPage) {
      return;
    }

    dispatch(loadDismissablePrompts({ promptKeys, promptTypes }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ...promptKeys, ...promptTypes, isLiveEventPage]);
};
