import { TypographyStyles, typographyStyles } from '@fluentui/react-components';
import { makeStyles } from '@griffel/react';

export type TypographyPreset = keyof TypographyStyles;

/**
 * @deprecated Use <Block textPreset="subtitle1"> or <Text preset="subtitle1"> instead of generating your own typography styles.
 */
export const typographyPresetStyles = typographyStyles;

export const useTypographyPresetClassName = (preset?: TypographyPreset) => {
  const typographyPresetClassNames = useTypographyPresetClassNames();

  return preset ? typographyPresetClassNames[preset] : undefined;
};

const useTypographyPresetClassNames = makeStyles<TypographyPreset>(typographyPresetStyles);

export const getFluentFontSizeTokenFromPreset = (preset: TypographyPreset) => typographyPresetStyles[preset].fontSize;
export const getFluentLineHeightTokenFromPreset = (preset: TypographyPreset) =>
  typographyPresetStyles[preset].lineHeight;
