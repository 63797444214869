import { getItem, removeItem, setItem } from '@ms/yammer-session-storage';
import { ScenarioCollection } from '@ms/yammer-telemetry-support';

import { yammerNamespace } from './telemetryStore';

const key = `${yammerNamespace}.ScenarioPerformance`;

type RemoveScenarioPerformanceCollection = () => void;
export const removeScenarioPerformanceCollection: RemoveScenarioPerformanceCollection = () => {
  removeItem(key);
};

type GetScenarioPerformanceCollection = () => ScenarioCollection;
export const getScenarioPerformanceCollection: GetScenarioPerformanceCollection = () =>
  getItem<ScenarioCollection>(key) || {};

type SetScenarioPerformanceCollection = (scenarioCollection: ScenarioCollection) => void;
export const setScenarioPerformanceCollection: SetScenarioPerformanceCollection = (
  scenarioCollection: ScenarioCollection
) => {
  setItem<ScenarioCollection>(key, scenarioCollection);
};
