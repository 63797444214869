import { feedPageUrl } from '@ms/yammer-web-support/dist/urls';
import { useLocation } from 'react-router-dom';

import { useIsFeatureRolloutEnabled } from '../featureRollouts';

type UseSuggestedActionsLightboxEnabled = () => boolean;

export const useIsSuggestedActionsLightboxEnabled: UseSuggestedActionsLightboxEnabled = () => {
  const isGroupsOverrideEnabled = useIsFeatureRolloutEnabled('SuggestedActionIgnoreMaxJoinedGroups');
  const isUsersOverrideEnabled = useIsFeatureRolloutEnabled('SuggestedActionFollowUsersIgnoreCheck');
  const { pathname } = useLocation();

  return pathname === feedPageUrl || isGroupsOverrideEnabled || isUsersOverrideEnabled;
};
