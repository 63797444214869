import { DeferredGlobalCopilotDrawer } from '@ms/yammer-copilot/dist/components/GlobalCopilot';
import { useCanCurrentUserAccessAdminSettings } from '@ms/yammer-hooks/dist/adminSettings';
import { useAndReportExperimentTreatment } from '@ms/yammer-hooks/dist/experiments';
import { useLoadFeatureAccessInsights } from '@ms/yammer-hooks/dist/featureAccessInsights';
import { useLoadMultiTenantOrganization } from '@ms/yammer-hooks/dist/multiTenantOrganization';
import { usePushSubscription } from '@ms/yammer-hooks/dist/pushSubscriptions';
import { useServiceWorkerMessages } from '@ms/yammer-hooks/dist/pwa';
import { useLoadStorylineSettings } from '@ms/yammer-hooks/dist/storylineSettings';
import { useComplianceChecks } from '@ms/yammer-hooks/dist/survey';
import { useLoadUserInformationBarrierSettings } from '@ms/yammer-hooks/dist/userInformationBarrierSettings';
import { useCurrentLocale } from '@ms/yammer-i18n-react';
import { usePersistLocale } from '@ms/yammer-i18n-react/dist/hooks';
import { AppContextProvider } from '@ms/yammer-libs-app-context';
import { ReduxAnnouncerBridge } from '@ms/yammer-web-components/dist/accessibility/Announcer';
import AppDocumentHeadOverrides from '@ms/yammer-web-components/dist/app/AppDocumentHeadOverrides';
import ArticleScrollProvider from '@ms/yammer-web-components/dist/articles/ArticleScrollContext';
import { LinkRenderingOptions, LinkRenderingOptionsProvider } from '@ms/yammer-web-components/dist/link/YammerLink';
import MediaLightbox from '@ms/yammer-web-components/dist/messageAttachments/MediaLightbox';
import RealtimeClient from '@ms/yammer-web-components/dist/realtime/RealtimeClient';
import SessionContextProviderWithHooks from '@ms/yammer-web-components/dist/session/SessionContextProviderWithHooks';
import { SendPresenceState } from '@ms/yammer-web-components/dist/userPresence';
import AppContainer from '@ms/yammer-web-core-ui/dist/components/AppContainer';
import { Toaster } from '@ms/yammer-web-core-ui/dist/fluent9/components/Toast';
import { usePrepareIcons } from '@ms/yammer-web-core-ui/dist/util/icons';
import { useMainFeatureSettings } from '@ms/yammer-web-ui-features/dist/featureSettings';
import { UIFeaturesProvider } from '@ms/yammer-web-ui-features/dist/features';
import React, { FC } from 'react';

import AppLayout from '../AppLayout';
import Dialogs from '../Dialogs';
import Routes from '../Routes';
import Skiplink from '../Skiplink';
import SuiteHeaderStatusProvider from '../SuiteHeader/SuiteHeaderStatusContext';

import { useThemeWithSuiteHeaderOverrides } from './hooks';

const App: FC = () => {
  const complianceChecks = useComplianceChecks();
  const isFeedbackEnabled = complianceChecks.policyAllowFeedback === 'Enabled';

  usePersistLocale();
  useServiceWorkerMessages();
  usePushSubscription();
  usePrepareIcons();
  useLoadStorylineSettings();
  useLoadMultiTenantOrganization();
  useLoadFeatureAccessInsights({ vivaEngagePremiumOnly: true });
  useLoadUserInformationBarrierSettings();

  const features = useMainFeatureSettings({
    overrides: {
      navigation: {
        admin: useCanCurrentUserAccessAdminSettings(),
      },
    },
  });
  const theme = useThemeWithSuiteHeaderOverrides();
  const locale = useCurrentLocale();

  const shouldOpenArticleInSameWindow = useAndReportExperimentTreatment('ArticleOpenSameWindow') === true;
  const linkRenderingOptions: LinkRenderingOptions = {
    shouldOpenArticleViewLinkInNewWindow: !shouldOpenArticleInSameWindow,
  };

  return (
    <AppContainer theme={theme} locale={locale}>
      <UIFeaturesProvider features={features}>
        <SessionContextProviderWithHooks>
          <AppContextProvider mainColumnAriaRole="main">
            <LinkRenderingOptionsProvider options={linkRenderingOptions}>
              <ArticleScrollProvider>
                <AppDocumentHeadOverrides />
                <Skiplink />
                <SuiteHeaderStatusProvider>
                  <AppLayout>
                    <Routes />
                  </AppLayout>
                </SuiteHeaderStatusProvider>
                <Dialogs />
                <MediaLightbox />
                <RealtimeClient />
                <ReduxAnnouncerBridge />
                <SendPresenceState />
                <DeferredGlobalCopilotDrawer isFeedbackEnabled={isFeedbackEnabled} />
                <Toaster />
              </ArticleScrollProvider>
            </LinkRenderingOptionsProvider>
          </AppContextProvider>
        </SessionContextProviderWithHooks>
      </UIFeaturesProvider>
    </AppContainer>
  );
};
export default App;
