import React, { FC, useMemo, useState } from 'react';

import { PageDataLoadContextProviderProps } from './PageDataLoadContextProvider.types';
import { PageDataLoadContext, PageDataLoadContextValue } from './context';
import { usePreloadPageDataCallback } from './hooks';
import { PageLoadData } from './types';

const PageDataLoadContextProvider: FC<PageDataLoadContextProviderProps> = ({ dataLoader, children }) => {
  const [pageLoadData, setPageLoadData] = useState<PageLoadData>(undefined);
  const preloadPageData = usePreloadPageDataCallback(setPageLoadData);

  useMemo(() => {
    if (dataLoader) {
      preloadPageData(dataLoader);
    }
  }, [dataLoader, preloadPageData]);

  const value = useMemo<PageDataLoadContextValue>(
    () => ({
      pageLoadData,
    }),
    [pageLoadData]
  );

  return <PageDataLoadContext.Provider value={value}>{children}</PageDataLoadContext.Provider>;
};

export default PageDataLoadContextProvider;
