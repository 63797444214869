import { InboxFeedType } from '@ms/yammer-graphql';

import { combinePath } from '../location/combinePath';

export const inboxRootPath = '/inbox';
export const inboxUnreadSubpath = '/unread';
export const inboxUnreadPageUrl = combinePath(inboxRootPath, inboxUnreadSubpath);
export const inboxDirectMessagesSubpath = '/direct';
export const inboxDirectMessagesPageUrl = combinePath(inboxRootPath, inboxDirectMessagesSubpath);
export const inboxAllSubpath = '/all';
export const inboxAllPageUrl = combinePath(inboxRootPath, inboxAllSubpath);

type InboxPageUrl = typeof inboxUnreadPageUrl | typeof inboxDirectMessagesPageUrl | typeof inboxAllPageUrl;
const inboxMap: Record<InboxFeedType, InboxPageUrl> = {
  [InboxFeedType.UNREAD]: inboxUnreadPageUrl,
  [InboxFeedType.DIRECT]: inboxDirectMessagesPageUrl,
  [InboxFeedType.ALL]: inboxAllPageUrl,
};

type InboxPage = (feedType: InboxFeedType) => string;
export const inboxPage: InboxPage = (feedType) => inboxMap[feedType];
