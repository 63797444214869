/* eslint-disable @ms/no-unnecessary-spread */
import { create } from 'zustand';

interface SearchSession {
  readonly conversationId: string;
  readonly conversationStart: number;
  readonly logicalId: string;
}
interface SearchSessionState {
  readonly globalSearchSession: SearchSession;
  readonly typeaheadSearchSession: SearchSession;

  readonly setSessionInformations: (sessionType: SessionType, sessionInformations: SearchSession) => void;
  readonly setLogicalId: (sessionType: SessionType, logicalId: string) => void;
  readonly resetSession: (sessionType: SessionType) => void;
}

export type SessionType = 'globalSearchSession' | 'typeaheadSearchSession';

const defaultSearchSession: SearchSession = {
  conversationId: 'ERROR - no-conversation-id',
  conversationStart: 0,
  logicalId: 'ERROR - no-logical-id',
};

export const useSearchSessionState = create<SearchSessionState>()((set) => ({
  globalSearchSession: { ...defaultSearchSession },
  typeaheadSearchSession: { ...defaultSearchSession },

  setSessionInformations: (sessionType: SessionType, sessionInformations: SearchSession) =>
    set(() => ({ [sessionType]: sessionInformations })),
  setLogicalId: (sessionType: SessionType, logicalId: string) =>
    set((state) => ({ [sessionType]: { ...state[sessionType], logicalId } })),
  resetSession: (sessionType: SessionType) => set(() => ({ [sessionType]: { ...defaultSearchSession } })),
}));

export const useSetSessionInformations = () => useSearchSessionState((state) => state.setSessionInformations);
export const useSetLogicalId = () => useSearchSessionState((state) => state.setLogicalId);
export const useResetSession = () => useSearchSessionState((state) => state.resetSession);
export const useSearchSession = (sessionType?: SessionType) =>
  useSearchSessionState((state) => {
    if (sessionType === 'typeaheadSearchSession') {
      return state.typeaheadSearchSession;
    }

    return state.globalSearchSession;
  });
