import { create } from 'zustand';

export type PermanentApp = 'App' | 'AppLiveEvent';
export interface PermanentAppState {
  readonly permanentApp?: PermanentApp;
  readonly setPermanentApp: (newValue: PermanentApp) => void;
}

export const usePermanentAppState = create<PermanentAppState>()((set) => ({
  permanentApp: undefined,
  setPermanentApp: (newValue) => set((state) => ({ permanentApp: state.permanentApp ? state.permanentApp : newValue })),
}));

type UseIsPermanentAppLiveEvent = () => boolean;
export const useIsPermanentAppLiveEvent: UseIsPermanentAppLiveEvent = () =>
  usePermanentAppState((state) => state.permanentApp === 'AppLiveEvent');
