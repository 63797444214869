import { usePrevious } from '@ms/yammer-web-core-ui/dist/util/usePrevious';
import { useEffect } from 'react';

import { useLocationSearchResultsParameters } from '../../hooks/useLocationSearchResultsParameters';
import { useResetSession } from '../state';

export const useEndActiveSession = () => {
  const { searchText } = useLocationSearchResultsParameters();
  const previous = usePrevious(searchText);
  const resetSession = useResetSession();

  useEffect(() => {
    if (!searchText && !!previous) {
      resetSession('globalSearchSession');
    }
  }, [searchText, previous, resetSession]);
};
