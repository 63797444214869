import { loadDismissablePrompts } from '@ms/yammer-data/dist/domains/dismissablePrompts/actions';
import { DismissablePromptType } from '@ms/yammer-data/dist/state/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useIsLiveEventPage } from '../liveEvents';

export type UseLoadDismissablePromptsByType = (promptTypes: DismissablePromptType[]) => void;
export const useLoadDismissablePromptsByType: UseLoadDismissablePromptsByType = (promptTypes) => {
  const dispatch = useDispatch();
  const isLiveEventPage = useIsLiveEventPage();

  useEffect(() => {
    if (isLiveEventPage) {
      return;
    }

    dispatch(loadDismissablePrompts({ promptTypes }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ...promptTypes, isLiveEventPage]);
};
