import { RefObject, createContext, useContext } from 'react';

interface ArticleScrollRefs {
  readonly scrollDestinationRef: RefObject<HTMLDivElement> | undefined;
}

const defaultOptions: ArticleScrollRefs = {
  scrollDestinationRef: undefined,
};

export const ArticleScrollContext = createContext(defaultOptions);

export const useArticleScrollContext = () => useContext(ArticleScrollContext);
