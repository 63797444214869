import {
  Publisher,
  ThreadScope,
  YammerState,
  isArticlePublisher,
  isGroupQuestionPublisher,
  isNetworkQuestionNewQuestionPublisher,
  isPollMessageContent,
  isEditPublisher as isPublisherForEditing,
  isQuestionPublisher,
  isReplyPublisher,
  isThreadScopeGroup,
  isThreadScopeUserStoryline,
  isUserQuestionPublisher,
} from '../../state/types';
import { getExperimentTreatment } from '../experiments/selectors';
import { findById as findMessageById, findByIdThrows as findMessageThrows } from '../messages/selectors';
import { findById as findThreadById, isSharePointThread, isThreadStarterForId } from '../threads/selectors';

import { findById, findByIdThrows } from './findPublisherSelectors';

type IsPublisherExists = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isPublisherExists: IsPublisherExists = (state, publisherId) => !!findById(state, publisherId);

type IsPollPostType = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isPollPostType: IsPollPostType = (state, publisherId) => {
  const publisher = findById(state, publisherId);

  if (publisher == null) {
    return false;
  }

  const { postType } = publisher;

  return postType === 'GroupNewPoll' || postType === 'UserNewPoll';
};

type IsPraisePostType = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isPraisePostType: IsPraisePostType = (state, publisherId) => {
  const publisher = findById(state, publisherId);

  if (publisher == null) {
    return false;
  }

  const { postType } = publisher;

  return postType === 'GroupNewPraise' || postType === 'UserNewPraise';
};

type IsQuestionPostType = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isQuestionPostType: IsQuestionPostType = (state, publisherId) => {
  const publisher = findById(state, publisherId);

  if (publisher == null) {
    return false;
  }

  const { postType, contentType } = publisher;

  return (
    postType === 'GroupNewQuestion' ||
    postType === 'UserNewQuestion' ||
    postType === 'TeamsMeetingNewQuestion' ||
    postType === 'NetworkQuestionNewQuestion' ||
    contentType === 'Question'
  );
};

type IsDiscussionPostType = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isDiscussionPostType: IsDiscussionPostType = (state, publisherId) => {
  const publisher = findById(state, publisherId);

  if (publisher == null) {
    return false;
  }

  return (
    !isQuestionPostType(state, publisherId) &&
    !isPraisePostType(state, publisherId) &&
    !isPollPostType(state, publisherId)
  );
};

type IsDirectReplyPublisher = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isDirectReplyPublisher: IsDirectReplyPublisher = (state, publisherId) => {
  const { destination, isReply } = findByIdThrows(state, publisherId);

  return destination === 'Direct' && isReply;
};

type IsReply = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isReply: IsReply = (state, publisherId) => {
  const { postType } = findByIdThrows(state, publisherId);

  return (
    postType === 'GroupReply' ||
    isDirectReplyPublisher(state, publisherId) ||
    postType === 'UserReply' ||
    postType === 'NetworkQuestionReply' ||
    postType === 'TeamsMeetingReply' ||
    postType === 'UserMomentReply'
  );
};

type IsEditingReply = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isEditingReply: IsEditingReply = (state, publisherId) => {
  const publisher = findByIdThrows(state, publisherId);

  if (!isPublisherForEditing(publisher)) {
    return false;
  }

  const { messageId } = publisher;

  return !isThreadStarterForId(state, messageId);
};

type IsReplyToSharePointThread = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isReplyToSharePointThread: IsReplyToSharePointThread = (state, publisherId) => {
  const publisher = findByIdThrows(state, publisherId);

  if (!isReplyPublisher(publisher)) {
    return false;
  }

  return isSharePointThread(state, publisher.threadId);
};

type GetPublisherPostType = (state: YammerState, publisher: Publisher['id']) => Publisher['postType'] | undefined;
export const getPublisherPostType: GetPublisherPostType = (state, publisherId) => {
  const publisher = findById(state, publisherId);

  return publisher?.postType;
};

type GetPublisherPostTypeThrows = (state: YammerState, publisher: Publisher['id']) => Publisher['postType'];
export const getPublisherPostTypeThrows: GetPublisherPostTypeThrows = (state, publisherId) => {
  const { postType } = findByIdThrows(state, publisherId);

  return postType;
};

type GetEditPublisherScope = (state: YammerState, publisherId: Publisher['id']) => ThreadScope | undefined;
export const getEditPublisherScope: GetEditPublisherScope = (state, publisherId) => {
  const publisher = findByIdThrows(state, publisherId);

  if (!isPublisherForEditing(publisher)) {
    return undefined;
  }

  const threadId = findMessageById(state, publisher.messageId)?.threadId;
  const scope = threadId ? findThreadById(state, threadId)?.scope : undefined;

  return scope;
};

type SupportsArticleFormatting = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const supportsArticleFormatting: SupportsArticleFormatting = (state, publisherId) => {
  const publisher = findByIdThrows(state, publisherId);
  if (!isPublisherForEditing(publisher)) {
    return isArticlePublisher(publisher);
  }

  const message = findMessageThrows(state, publisher.messageId);

  return message.isArticle;
};

type IsEditingTitleEnabledQuestion = (state: YammerState, publisherId: Publisher['id']) => boolean;
const isEditingTitleEnabledQuestion: IsEditingTitleEnabledQuestion = (state, publisherId) => {
  const isQuestionTitlesEnabled = !!getExperimentTreatment(state, 'QuestionTitles');

  const publisher = findByIdThrows(state, publisherId);

  if (!isPublisherForEditing(publisher) || isEditingReply(state, publisherId) || !isQuestionTitlesEnabled) {
    return false;
  }

  return findMessageThrows(state, publisher.messageId).isQuestion;
};

type IsTitleEnabledQuestionPublisher = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isTitleEnabledQuestionPublisher: IsTitleEnabledQuestionPublisher = (state, publisherId) => {
  const publisher = findById(state, publisherId);

  if (!publisher) {
    return false;
  }

  const isQuestionTitlesEnabled = !!getExperimentTreatment(state, 'QuestionTitles');

  return (
    (isQuestionTitlesEnabled && isQuestionPublisher(publisher)) || isEditingTitleEnabledQuestion(state, publisherId)
  );
};

type IsEditingPoll = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isEditingPoll: IsEditingPoll = (state, publisherId) => {
  const publisher = findByIdThrows(state, publisherId);

  if (!isPublisherForEditing(publisher)) {
    return false;
  }
  const message = findMessageThrows(state, publisher.messageId);

  return isPollMessageContent(message.content);
};

type IsEditingNetworkQuestionNewQuestion = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isEditingNetworkQuestionNewQuestion: IsEditingNetworkQuestionNewQuestion = (state, publisherId) => {
  const threadScope = getEditPublisherScope(state, publisherId);
  const isNetworkQuestionThreadScope = threadScope?.subtype === 'NetworkQuestionScope';
  const isReplyPublisher = isEditingReply(state, publisherId);

  return isNetworkQuestionThreadScope && !isReplyPublisher;
};

type IsEditingVivaEngageQuestion = (state: YammerState, publisherId: Publisher['id']) => boolean;
const isEditingVivaEngageQuestion: IsEditingVivaEngageQuestion = (state, publisherId) => {
  const publisher = findByIdThrows(state, publisherId);

  if (
    !isPublisherForEditing(publisher) ||
    isEditingReply(state, publisherId) ||
    isEditingNetworkQuestionNewQuestion(state, publisherId)
  ) {
    return false;
  }

  return findMessageThrows(state, publisher.messageId).isQuestion;
};

type IsVivaEngageQuestionPublisher = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isVivaEngageQuestionPublisher: IsVivaEngageQuestionPublisher = (state, publisherId) => {
  const publisher = findByIdThrows(state, publisherId);

  if (isNetworkQuestionNewQuestionPublisher(publisher)) {
    return false;
  }

  return isQuestionPublisher(publisher) || isEditingVivaEngageQuestion(state, publisherId);
};

type IsEditingVivaEngageGroupQuestion = (state: YammerState, publisherId: Publisher['id']) => boolean;
const isEditingVivaEngageGroupQuestion: IsEditingVivaEngageGroupQuestion = (state, publisherId) => {
  const scope = getEditPublisherScope(state, publisherId);

  if (!scope) {
    return false;
  }

  return isEditingVivaEngageQuestion(state, publisherId) && isThreadScopeGroup(scope);
};

type IsEditingVivaEngageUserStoryQuestion = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isEditingVivaEngageUserStoryQuestion: IsEditingVivaEngageUserStoryQuestion = (state, publisherId) => {
  const scope = getEditPublisherScope(state, publisherId);

  if (!scope) {
    return false;
  }

  return isEditingVivaEngageQuestion(state, publisherId) && isThreadScopeUserStoryline(scope);
};

type IsVivaEngageGroupQuestionPublisher = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isVivaEngageGroupQuestionPublisher: IsVivaEngageGroupQuestionPublisher = (state, publisherId) => {
  const publisher = findByIdThrows(state, publisherId);

  if (isNetworkQuestionNewQuestionPublisher(publisher)) {
    return false;
  }

  return isGroupQuestionPublisher(publisher) || isEditingVivaEngageGroupQuestion(state, publisherId);
};

type IsVivaEngageUserStorylineQuestionPublisher = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isVivaEngageUserStorylineQuestionPublisher: IsVivaEngageUserStorylineQuestionPublisher = (
  state,
  publisherId
) => {
  const publisher = findByIdThrows(state, publisherId);

  if (isNetworkQuestionNewQuestionPublisher(publisher)) {
    return false;
  }

  return isUserQuestionPublisher(publisher) || isEditingVivaEngageUserStoryQuestion(state, publisherId);
};

type IsEditingGroupOrStorylinePublisher = (state: YammerState, publisherId: Publisher['id']) => boolean;
const isEditingGroupOrStorylinePublisher: IsEditingGroupOrStorylinePublisher = (state, publisherId) => {
  if (!isEditingVivaEngageQuestion(state, publisherId)) {
    return false;
  }

  return (
    isEditingVivaEngageGroupQuestion(state, publisherId) || isEditingVivaEngageUserStoryQuestion(state, publisherId)
  );
};

type IsQuestionTitleV2EnabledPublisher = (state: YammerState, publisherId: Publisher['id']) => boolean;
export const isQuestionTitleV2EnabledPublisher: IsQuestionTitleV2EnabledPublisher = (state, publisherId) => {
  const publisher = findById(state, publisherId);
  const isQuestionTitlesV2Enabled = !!getExperimentTreatment(state, 'QuestionTitlesV2');

  if (!isQuestionTitlesV2Enabled || publisher == null) {
    return false;
  }

  return (
    isGroupQuestionPublisher(publisher) ||
    isUserQuestionPublisher(publisher) ||
    isEditingGroupOrStorylinePublisher(state, publisherId)
  );
};
