export const HighContrastEmulationClassName = 'emulate-hc';
export const SuppressHighContrastEmulationBGColorClassName = 'suppress-hc-bgcolor';

export const HighContrastEmulationColorMap = {
  windowtext: 'white', // Text color
  window: 'black', // Background color
  linktext: '#FFFD54', // Link color
  graytext: '#00FF00', // Text color for disabled elements (inc. placeholder text)
  buttontext: 'white', // Button text color
  buttonbackground: 'black', // Button background color
  highlighttext: 'black', // Selected text color
  highlight: '#6CE7FC', // Selected background color.
};

export const HighContrastContextualMenuStyles = {
  list: {
    selectors: {
      [`.${HighContrastEmulationClassName} &`]: {
        border: `1px solid ${HighContrastEmulationColorMap.windowtext}`,
      },
    },
  },
  subComponentStyles: {
    callout: {
      selectors: {
        [`.${HighContrastEmulationClassName} &`]: {
          border: `1px solid ${HighContrastEmulationColorMap.windowtext}`,
        },
      },
    },
    menuItem: {
      root: {
        selectors: {
          [`.${HighContrastEmulationClassName} &`]: {
            selectors: {
              ':focus *, :focus': {
                color: `${HighContrastEmulationColorMap.highlighttext} !important`,
                borderColor: `${HighContrastEmulationColorMap.highlighttext} !important`,
                backgroundColor: `${HighContrastEmulationColorMap.highlight} !important`,
              },
            },
          },
        },
      },
    },
  },
};

export const HighContrastEmulationSelectorStyles = {
  [`:global(body.${HighContrastEmulationClassName})`]: {
    '*': {
      backgroundColor: `${HighContrastEmulationColorMap.window} !important`,
      color: `${HighContrastEmulationColorMap.windowtext} !important`,
    },
    [`.${SuppressHighContrastEmulationBGColorClassName}, .${SuppressHighContrastEmulationBGColorClassName} *, .ms-Layer, .ms-Layer-content, [role="dialog"], .ms-Modal, .ms-Overlay, .ms-Panel`]:
      {
        backgroundColor: `unset !important`,
      },
    'input, textarea': {
      color: `${HighContrastEmulationColorMap.windowtext} !important`,
    },
    'input::placeholder': {
      color: `${HighContrastEmulationColorMap.graytext} !important`,
    },
    'a[href]': {
      borderColor: `${HighContrastEmulationColorMap.linktext} !important`,
      color: `${HighContrastEmulationColorMap.linktext} !important`,
    },
    '*::selection': {
      color: `${HighContrastEmulationColorMap.highlighttext} !important`,
      backgroundColor: `${HighContrastEmulationColorMap.highlight} !important`,
    },
    'button, input[type=button], input[type=submit]': {
      backgroundColor: `${HighContrastEmulationColorMap.buttonbackground} !important`,
      color: `${HighContrastEmulationColorMap.buttontext} !important`,
      borderColor: `${HighContrastEmulationColorMap.buttontext} !important`,
    },
    'button:disabled': {
      color: `${HighContrastEmulationColorMap.graytext} !important`,
      borderColor: `${HighContrastEmulationColorMap.graytext} !important`,
    },
    'button *, a[href] *': {
      color: 'inherit !important',
    },
    'button *': {
      forcedColorAdjust: 'auto',
    },
  },
};
