let policy;
// istanbul ignore next
// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (typeof (globalThis as any).trustedTypes !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  policy = (globalThis as any).trustedTypes.createPolicy('dangerouslySetInnerHTMLPolicy', {
    createHTML: (string: string) => string,
  });
}

const fallbackPolicy = {
  createHTML: (string: string) => string,
};

/**
 * THIS IS DANGEROUS.
 *
 * Setting innerHTML without properly sanitizing it can lead to XSS security vulnerabilities.
 * Only use this policy if you know for certain the string is safe to use.
 */
export const dangerouslySetInnerHTMLPolicy = policy || fallbackPolicy;
