import { getItem, removeItem, setItem } from '@ms/yammer-session-storage';
import { FirstMeaningfulPaintContext } from '@ms/yammer-telemetry-support';

import { yammerNamespace } from './telemetryStore';

const key = `${yammerNamespace}.FirstMeaningfulPaintContext`;

const defaultFirstMeaningfulPaintContext: FirstMeaningfulPaintContext = {
  firstMeaningfulPaintReported: false,
  firstPageLoadReported: false,
  firstPageLoadAbandoned: false,
};
type GetFirstMeaningfulPaintContext = () => FirstMeaningfulPaintContext;
export const getFirstMeaningfulPaintContext: GetFirstMeaningfulPaintContext = () =>
  getItem<FirstMeaningfulPaintContext>(key) || defaultFirstMeaningfulPaintContext;

type SetFirstMeaningfulPaintContext = (firstMeaningfulPaintContext: FirstMeaningfulPaintContext) => void;
export const setFirstMeaningfulPaintContext: SetFirstMeaningfulPaintContext = (
  firstMeaningfulPaintContext: FirstMeaningfulPaintContext
) => {
  setItem<FirstMeaningfulPaintContext>(key, firstMeaningfulPaintContext);
};

type RemoveFirstMeaningfulPaintContext = () => void;
export const removeFirstMeaningfulPaintContext: RemoveFirstMeaningfulPaintContext = () => {
  removeItem(key);
};
