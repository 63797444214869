import { createContext, useContext } from 'react';

export interface LinkRenderingOptions {
  readonly disableLinks?: boolean;
  readonly disableLivePersonaCard?: boolean;
  readonly disableUserContentLinks?: boolean;
  readonly disableReplyLinks?: boolean;
  readonly disableThreadLinks?: boolean;
  readonly isThreadStarterCopyButtonLinkExternal?: boolean;
  readonly shouldHideDownloadAttachmentLink?: boolean;
  readonly shouldHideLegacyPageLinks?: boolean;
  readonly shouldHideDescriptionMoreInfoLink?: boolean;
  readonly shouldOpenArticleViewLinkInNewWindow?: boolean;
  readonly shouldOpenArticlePublisherLinkInNewWindow?: boolean;
  readonly shouldOpenAskMeAnythingLinkInNewWindow?: boolean;
  readonly shouldOpenDiscoverCommunitiesLinkInNewWindow?: boolean;
  readonly shouldOpenEventLinkInNewWindow?: boolean;
  readonly shouldOpenGroupFilesInNewWindow?: boolean;
  readonly shouldOpenGroupFilesInLegacyPage?: boolean;
  readonly shouldOpenGroupHeaderSettingsLinkInNewWindow?: boolean;
  readonly shouldOpenGroupInfoLinkInNewWindow?: boolean;
  readonly shouldOpenGroupDeleteInNewWindow?: boolean;
  readonly shouldOpenGroupLinkInNewWindow?: boolean;
  readonly shouldOpenThreadLinkInNewWindow?: boolean;
  readonly shouldOpenTagLinkInNewWindow?: boolean;
  readonly shouldOpenTopicLinkInNewWindow?: boolean;
  readonly shouldOpenUserLinkInNewWindow?: boolean;
  readonly shouldOpenViewConversationLinkInNewWindow?: boolean;
  readonly shouldOpenYammerLinkInNewWindow?: boolean;
  readonly shouldShowBackButton?: boolean;
  readonly shouldOpenSharedUserStorylineThreadLinkAsTBE?: boolean;
}

export const defaultOptions: LinkRenderingOptions = {
  disableLinks: false,
  disableLivePersonaCard: false,
  disableUserContentLinks: false,
  disableReplyLinks: false,
  disableThreadLinks: false,
  isThreadStarterCopyButtonLinkExternal: false,
  shouldHideDownloadAttachmentLink: false,
  shouldHideLegacyPageLinks: false,
  shouldHideDescriptionMoreInfoLink: false,
  shouldOpenArticleViewLinkInNewWindow: false,
  shouldOpenArticlePublisherLinkInNewWindow: false,
  shouldOpenAskMeAnythingLinkInNewWindow: false,
  shouldOpenDiscoverCommunitiesLinkInNewWindow: false,
  shouldOpenEventLinkInNewWindow: false,
  shouldOpenGroupFilesInNewWindow: false,
  shouldOpenGroupFilesInLegacyPage: false,
  shouldOpenGroupHeaderSettingsLinkInNewWindow: false,
  shouldOpenGroupDeleteInNewWindow: false,
  shouldOpenGroupInfoLinkInNewWindow: false,
  shouldOpenGroupLinkInNewWindow: false,
  shouldOpenThreadLinkInNewWindow: false,
  shouldOpenTagLinkInNewWindow: false,
  shouldOpenTopicLinkInNewWindow: false,
  shouldOpenUserLinkInNewWindow: false,
  shouldOpenViewConversationLinkInNewWindow: false,
  shouldOpenYammerLinkInNewWindow: false,
  shouldShowBackButton: true,
  shouldOpenSharedUserStorylineThreadLinkAsTBE: false,
};

export const LinkRenderingContext = createContext(defaultOptions);

export type UseLinkRenderingOptions = () => LinkRenderingOptions;
export const useLinkRenderingOptions: UseLinkRenderingOptions = () => useContext(LinkRenderingContext);
