import { loadAllLeadersActionCreator } from '@ms/yammer-data/dist/domains/leadersManagement/loadAllLeadersActions';
import { LoadAllLeadersInUsersNetworkRequestOptions } from '@ms/yammer-data/dist/domains/leadersManagement/sharedAllLeaders';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useIsLiveEventPage } from '../liveEvents';

export type UseLoadAllLeadersOptions = Omit<LoadAllLeadersInUsersNetworkRequestOptions, 'endCursor'>;
type UseLoadAllLeaders = (options: UseLoadAllLeadersOptions) => void;
export const useLoadAllLeaders: UseLoadAllLeaders = (options) => {
  const dispatch = useDispatch();
  const {
    leadersCount,
    includeUserCoverImageFields,
    includeViewerIsFollowing,
    filterBy,
    sortBy,
    includeLeaderAudiences,
  } = options;
  const isLiveEventPage = useIsLiveEventPage();

  useEffect(() => {
    if (isLiveEventPage) {
      return;
    }

    dispatch(
      loadAllLeadersActionCreator({
        endCursor: null,
        leadersCount,
        includeUserCoverImageFields,
        includeViewerIsFollowing,
        filterBy,
        sortBy,
        includeLeaderAudiences,
      })
    );
  }, [
    dispatch,
    leadersCount,
    includeUserCoverImageFields,
    includeViewerIsFollowing,
    filterBy,
    sortBy,
    includeLeaderAudiences,
    isLiveEventPage,
  ]);
};
