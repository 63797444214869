import { noop } from 'lodash';
import { createContext } from 'react';

interface SuiteHeaderStatusContextProps {
  readonly isSuiteHeaderFinishedLoading: boolean;
  readonly setSuiteHeaderFinishedLoading: (isSuiteHeaderFinishedLoading: boolean) => void;
}

export const SuiteHeaderStatusContext = createContext<SuiteHeaderStatusContextProps>({
  isSuiteHeaderFinishedLoading: false,
  setSuiteHeaderFinishedLoading: noop,
});
