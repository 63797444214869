import { Campaign, Group, Thread, User } from '@ms/yammer-data/dist/state/types';

type InsightsRootPath = '/insights';

export const insightsRootPath: InsightsRootPath = '/insights';

export const insightsSelectedAudienceLeaderSubpath = '/leaders/:leaderId?';
export const insightsMyInsightsSubpath = '/users/myInsights';
export const insightsSelectedLeaderMyInsightsSubpath = '/users/myInsights/leaders/:leaderId';
export const insightsQnAMyInsightsSubpath = '/qna/myInsights';
export const insightsQnAOrgSubpath = '/qna/all';
export const insightsSelectedCampaignSubpath = '/campaign/:campaignId?';
export const insightsNetworkSubpath = '/network';
export const insightsPremiumSelectedGroupSubpath = '/groups/:groupId?';

const insightsCampaignSubpath = '/campaign';
const insightsLeadersSubpath = '/leaders';

export const insightsLeadersPage: `${InsightsRootPath}${typeof insightsLeadersSubpath}` = `${insightsRootPath}${insightsLeadersSubpath}`;
export const insightsSelectedLeadersOrgPage: `${InsightsRootPath}${typeof insightsSelectedAudienceLeaderSubpath}` = `${insightsRootPath}${insightsSelectedAudienceLeaderSubpath}`;
export const insightsMyInsightsPage: `${InsightsRootPath}${typeof insightsMyInsightsSubpath}` = `${insightsRootPath}${insightsMyInsightsSubpath}`;
export const insightsSelectedLeaderMyInsightsPage: `${InsightsRootPath}${typeof insightsSelectedLeaderMyInsightsSubpath}` = `${insightsRootPath}${insightsSelectedLeaderMyInsightsSubpath}`;
export const insightsQnAOrgPage: `${InsightsRootPath}${typeof insightsQnAOrgSubpath}` = `${insightsRootPath}${insightsQnAOrgSubpath}`;
export const insightsQnAMyInsightsPage: `${InsightsRootPath}${typeof insightsQnAMyInsightsSubpath}` = `${insightsRootPath}${insightsQnAMyInsightsSubpath}`;
export const insightsCampaignPage: `${InsightsRootPath}${typeof insightsCampaignSubpath}` = `${insightsRootPath}${insightsCampaignSubpath}`;
export const insightsPremiumCampaignPage: `${InsightsRootPath}${typeof insightsSelectedCampaignSubpath}` = `${insightsRootPath}${insightsSelectedCampaignSubpath}`;
export const insightsNetworkPage: `${InsightsRootPath}${typeof insightsNetworkSubpath}` = `${insightsRootPath}${insightsNetworkSubpath}`;
export const insightsPremiumGroupsPage = '/insights/groups';
export const insightsPremiumGroupPage: `${InsightsRootPath}${typeof insightsPremiumSelectedGroupSubpath}` = `${insightsRootPath}${insightsPremiumSelectedGroupSubpath}`;
export const insightsPremiumGroupOptimizationsPage = '/insights/group-optimizations/:groupId';
export const insightsConversationInsightsPage = '/threads/:threadId/insights';
export const insightsStorylinePage = '/users/:userId/storyline-insights';

type InsightsCampaignPageWithCampaignId = (campaignId: Campaign['id']) => string;
export const insightsCampaignPageWithCampaignId: InsightsCampaignPageWithCampaignId = (campaignId) =>
  `/insights/campaign/${campaignId}`;

type InsightsPersonalWithLeaderId = (leaderId: User['id']) => string;
export const insightsPersonalWithLeaderId: InsightsPersonalWithLeaderId = (leaderId) =>
  insightsSelectedLeaderMyInsightsPage.replace(':leaderId', leaderId);

type InsightsAudienceWithLeaderId = (leaderId: User['id']) => string;
export const insightsAudienceWithLeaderId: InsightsAudienceWithLeaderId = (leaderId) =>
  insightsSelectedLeadersOrgPage.replace(':leaderId?', leaderId);

type InsightsPremiumGroupWithGroupId = (groupId: Group['id']) => string;
export const insightsPremiumGroupWithGroupId: InsightsPremiumGroupWithGroupId = (groupId) =>
  insightsPremiumGroupPage.replace(':groupId?', groupId);

export const getInsightsPremiumGroupWithGroupIdAndTimeframe = (groupId: Group['id'], timeframe: string) =>
  `${insightsPremiumGroupWithGroupId(groupId)}?timeframe=${timeframe}`;

export const getInsightsPremiumGroupWithGroupIdAndTimestamps = ({
  groupId,
  startDateTimestamp,
  endDateTimestamp,
}: {
  readonly groupId: Group['id'];
  readonly startDateTimestamp: number;
  readonly endDateTimestamp: number;
}): string => `${insightsPremiumGroupWithGroupId(groupId)}?startDate=${startDateTimestamp}&endDate=${endDateTimestamp}`;

type InsightsPremiumGroupOptimizationsWithGroupId = (groupId: Group['id']) => string;
export const insightsPremiumGroupOptimizationsWithGroupId: InsightsPremiumGroupOptimizationsWithGroupId = (groupId) =>
  insightsPremiumGroupOptimizationsPage.replace(':groupId', groupId);

export const getInsightsPremiumGroupOptimizationsWithGroupIdAndTimeframe = (groupId: Group['id'], timeframe: string) =>
  `${insightsPremiumGroupOptimizationsWithGroupId(groupId)}?timeframe=${timeframe}`;

export const getInsightsPremiumGroupOptimizationsWithGroupIdAndTimestamps = ({
  groupId,
  startDateTimestamp,
  endDateTimestamp,
}: {
  readonly groupId: Group['id'];
  readonly startDateTimestamp: number;
  readonly endDateTimestamp: number;
}) =>
  `${insightsPremiumGroupOptimizationsWithGroupId(
    groupId
  )}?startDate=${startDateTimestamp}&endDate=${endDateTimestamp}`;

type InsightsConversationInsightsWithThreadId = (threadId: Thread['id']) => string;
export const insightsConversationInsightsWithThreadId: InsightsConversationInsightsWithThreadId = (threadId) =>
  insightsConversationInsightsPage.replace(':threadId', threadId);

type InsightsStorylinePageWithUserId = (userId: User['id']) => string;
export const insightsStorylinePageWithUserId: InsightsStorylinePageWithUserId = (userId) =>
  insightsStorylinePage.replace(':userId', userId);
