import { lazyLoadOnRender } from '@ms/yammer-libs-lazy';
import { combinePath } from '@ms/yammer-web-support/dist/location';
import React, { FC, ReactElement, memo, useLayoutEffect, useMemo } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import PageDataLoadContextProvider from '../PageDataLoadContextProvider';
import PageRouteAssetPreloadProvider from '../PageRouteAssetPreloadProvider';
import { usePageTelemetryContextSetRouteCallback } from '../PageTelemetryContextProvider/hooks';

import { AppPageRoutesWithTelemetryProps, PageRoute, ParentPageRoute } from './AppPageRoutesWithTelemetry.types';
import SubrouteComponentPreloader from './SubrouteComponentPreloader';
import { enforceNoDuplicatePageNames } from './enforceNoDuplicatePageNames';
import { isParentRoute } from './isParentRoute';

interface LoadablePageContentWithTelemetryProps {
  readonly route: PageRoute;
}
const LoadablePageContentWithTelemetry: FC<LoadablePageContentWithTelemetryProps> = ({ route }) => {
  const LoadablePageContent = useMemo(
    () =>
      lazyLoadOnRender({
        loader: route.loader,
      }),
    [route.loader]
  );
  const { pathname } = useLocation();

  const setRoute = usePageTelemetryContextSetRouteCallback();

  useLayoutEffect(() => {
    setRoute?.(route, pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return (
    <PageDataLoadContextProvider key={pathname} dataLoader={route.dataLoader}>
      <LoadablePageContent />
    </PageDataLoadContextProvider>
  );
};

type GetRouteElement = (route: PageRoute) => ReactElement;
const getRouteElement: GetRouteElement = (route) => (
  <Route
    key={route.path}
    path={route.path}
    exact={route.exact}
    render={() => <LoadablePageContentWithTelemetry route={route} />}
  />
);
interface LoadableParentContentProps {
  readonly parentRoute: ParentPageRoute;
}
const LoadableParentContent: FC<LoadableParentContentProps> = memo(({ parentRoute }) => {
  const LoadableParentPageContent = useMemo(
    () =>
      lazyLoadOnRender({
        loader: parentRoute.loader,
        fallback: <SubrouteComponentPreloader parentRoute={parentRoute} />,
      }),
    [parentRoute]
  );

  const { pathname } = useLocation();
  const match = useRouteMatch({
    path: parentRoute.path,
  });
  const parentRoutePath = match ? match.url : pathname;
  const routeElements = parentRoute.childRoutes.map((route) =>
    getRouteElement({ ...route, path: combinePath(parentRoute.path, route.path) })
  );
  routeElements.push(getRouteElement({ ...parentRoute.fallbackRedirectRoute, path: parentRoute.path }));

  return (
    <PageDataLoadContextProvider key={parentRoutePath} dataLoader={parentRoute.dataLoader}>
      <LoadableParentPageContent>
        <Switch>{routeElements}</Switch>
      </LoadableParentPageContent>
    </PageDataLoadContextProvider>
  );
});

type GetParentRouteElement = (parentRoute: ParentPageRoute) => ReactElement;
const getParentRouteElement: GetParentRouteElement = (parentRoute) => (
  <Route
    key={parentRoute.path}
    path={parentRoute.path}
    render={() => <LoadableParentContent parentRoute={parentRoute} />}
  />
);

const AppPageRoutesWithTelemetry: FC<AppPageRoutesWithTelemetryProps> = ({ routes }) => {
  enforceNoDuplicatePageNames(routes);

  const pageRoutes = routes.map((route) =>
    isParentRoute(route) ? getParentRouteElement(route) : getRouteElement(route)
  );

  return (
    <PageRouteAssetPreloadProvider routes={routes}>
      <Switch>{pageRoutes}</Switch>
    </PageRouteAssetPreloadProvider>
  );
};

export default memo(AppPageRoutesWithTelemetry);
