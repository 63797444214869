import { RefObject, createContext } from 'react';

export type PageSection = 'feed' | 'pinnedThreads';

export type PageSectionRefs = Record<PageSection, RefObject<HTMLDivElement> | undefined>;

export const defaultPageSectionRefs: PageSectionRefs = {
  feed: undefined,
  pinnedThreads: undefined,
};

export const PageSectionRefsContext = createContext(defaultPageSectionRefs);
export interface InMainWindowContextOptions {
  readonly inMainWindow: boolean;
}

export const defaultOptions: InMainWindowContextOptions = {
  inMainWindow: true,
};

export const InMainWindowContext = createContext(defaultOptions);
