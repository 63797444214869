import { useCurrentSessionUser } from '@ms/yammer-hooks/dist/session';

import { UseUIFeatureCheck } from '../types';

export {
  useCanCurrentUserAccessAdminSettings as useIsAdminCenterEnabled,
  useShowStorylineAdminSettings as useIsStorylineAdminEnabled,
} from '@ms/yammer-hooks/dist/adminSettings';

export {
  useCanEditNetworkQuestionSettings as useIsQuestionsAdminEnabled,
  useShowAnalyticsSettings as useIsInsightsAdminEnabled,
  useCanViewerEditLeaderSettings as useIsLeadershipCornerAdminEnabled,
  useViewerCanManageComplianceData as useIsComplianceDataAdminEnabled,
  useAdminCanEditViewOnlyMode as useIsViewOnlyModeAdminEnabled,
  useAdminCanHideUsersForNetworkInDiscovery as useIsAdminMuteUserAdminEnabled,
  useViewerCanManageMultiTenantOrganizationPolicy as useIsMTOPolicyAdminEnabled,
  useViewerCanManageInformationBarrierSettings as useIsInformationBarrierSettingsEnabled,
} from '@ms/yammer-hooks/dist/adminFeatureAccessSettings';
export { useCanAssignUserAdminRoles as useIsUserRolesAdminEnabled } from '@ms/yammer-hooks/dist/featureAccessSettings';
export { useViewerCanEditUserAchievementsSettings as useIsAchievementsAdminEnabled } from '@ms/yammer-hooks/dist/adminUserAchievementsSettings';
export { useShowLeaderSettings as useIsLeadersAdminEnabled } from '@ms/yammer-hooks/dist/leaderSettings';

export const useIsNetworkAdminEnabled: UseUIFeatureCheck = () => useCurrentSessionUser().isNetworkAdmin;
