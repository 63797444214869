import React, { FC, ReactNode, useState } from 'react';

import { SuiteHeaderStatusContext } from './context';

interface SuiteHeaderStatusProviderProps {
  readonly children: ReactNode;
}

const SuiteHeaderStatusProvider: FC<SuiteHeaderStatusProviderProps> = ({ children }) => {
  const [isSuiteHeaderFinishedLoading, setSuiteHeaderFinishedLoading] = useState<boolean>(false);

  return (
    <SuiteHeaderStatusContext.Provider value={{ isSuiteHeaderFinishedLoading, setSuiteHeaderFinishedLoading }}>
      {children}
    </SuiteHeaderStatusContext.Provider>
  );
};

export default SuiteHeaderStatusProvider;
