import { lazyLoadOnRender, loadModule } from '@ms/yammer-libs-lazy';

const loader = () => import(/* webpackChunkName: "4-feat-settings-nav" */ './UserSettingsNav');

const mobileLoader = () => import(/* webpackChunkName: "4-feat-settings-nav-mobile" */ './MobileUserSettingsNav');

export const preloadUserSettingsNav: () => void = () => {
  loadModule(loader);
};

export const LoadableUserSettingsNav = lazyLoadOnRender({ loader });

export const LoadableMobileUserSettingsNav = lazyLoadOnRender({ loader: mobileLoader });
