import { useIsFeatureRolloutEnabled } from '../featureRollouts';
import { useCanCurrentUserAccessStoryline } from '../userProfile';

type UseCanCurrentUserAccessStorylineHome = () => boolean;
export const useCanCurrentUserAccessStorylineHome: UseCanCurrentUserAccessStorylineHome = () => {
  const isStorylineHomeEnabled = useIsFeatureRolloutEnabled('StorylineHome');
  const canCurrentUserAccessStoryline = useCanCurrentUserAccessStoryline();

  return isStorylineHomeEnabled && canCurrentUserAccessStoryline;
};
