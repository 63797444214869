/* istanbul ignore file */
import { merge } from '@fluentui/react/lib/Utilities';

import { getSemanticColorOverridesFromPalette } from '../colors';
import { DefaultColors, NeutralColors, SharedColors, YammerColors } from '../colors/yammerPalette';
import { getBaseThemeFontSizeStyles } from '../styles/fonts';
import { IPalette, ITheme, createTheme } from '../styling';

const accentColorOverrides = {
  accent: YammerColors.yammerPrimary,
  blueDark: SharedColors.cyanBlue20,
  blueLight: YammerColors.yammerTint30,
  blueMid: YammerColors.yammerTint20,
  magenta: SharedColors.magenta10,
  magentaDark: SharedColors.magenta20,
  orange: SharedColors.orange20,
  orangeLight: YammerColors.heartRed,
  orangeLighter: SharedColors.orange10,
  purpleLight: SharedColors.blueMagenta10,
  purpleDark: SharedColors.blueMagenta20,
  green: DefaultColors.green,
  greenDark: DefaultColors.greenDark,
  greenLight: DefaultColors.greenLight,
  red: SharedColors.red10,
  teal: SharedColors.cyan20,
  tealDark: SharedColors.cyan30,
  yellowLight: SharedColors.yellow10,
};

const darkAccentColorOverrides = {
  ...accentColorOverrides,
  accent: YammerColors.yammerTint30,
  blueDark: SharedColors.cyanBlue20,
  blueLight: YammerColors.yammerTint30,
  blueMid: YammerColors.yammerTint20,
  magenta: SharedColors.magenta10,
  magentaDark: SharedColors.magentaPink10,
  magentaLight: SharedColors.magenta20,
  orange: SharedColors.orange20,
  orangeLight: YammerColors.heartRed,
  orangeLighter: SharedColors.orange10,
  purple: DefaultColors.purple,
  purpleLight: SharedColors.blueMagenta10,
  purpleDark: SharedColors.blueMagenta20,
  red: SharedColors.red20,
  redDark: SharedColors.red10,
  teal: SharedColors.cyan20,
  tealDark: SharedColors.cyan30,
  tealLight: DefaultColors.tealLight,
  yellow: DefaultColors.yellow,
  yellowDark: SharedColors.yellow10,
  yellowLight: DefaultColors.yellowDark,
};

const themeColors = {
  themeDarker: NeutralColors.gray220,
  themeDark: NeutralColors.gray210,
  themeDarkAlt: NeutralColors.gray190,
  themePrimary: NeutralColors.black,
  themeSecondary: NeutralColors.gray150,
  themeTertiary: NeutralColors.gray130,
  themeLight: NeutralColors.gray90,
  themeLighter: NeutralColors.gray40,
  themeLighterAlt: NeutralColors.gray10,
};

const darkThemeColors = {
  themeDarker: NeutralColors.gray20,
  themeDark: NeutralColors.gray40,
  themeDarkAlt: NeutralColors.gray50,
  themePrimary: NeutralColors.white,
  themeSecondary: NeutralColors.gray60,
  themeTertiary: NeutralColors.gray90,
  themeLight: NeutralColors.gray140,
  themeLighter: NeutralColors.gray190,
  themeLighterAlt: NeutralColors.gray220,
};

const defaultPalette: Partial<IPalette> = {
  neutralDark: NeutralColors.gray220,
  neutralPrimary: NeutralColors.gray210,
  neutralPrimaryAlt: NeutralColors.gray150,
  neutralSecondary: NeutralColors.gray130,
  neutralSecondaryAlt: NeutralColors.gray110,
  neutralTertiary: NeutralColors.gray90,
  neutralTertiaryAlt: NeutralColors.gray60,
  neutralQuaternary: NeutralColors.gray50,
  neutralQuaternaryAlt: NeutralColors.gray40,
  neutralLight: NeutralColors.gray30,
  neutralLighter: NeutralColors.gray20,
  neutralLighterAlt: NeutralColors.gray10,
  white: NeutralColors.white,
  black: NeutralColors.black,
  ...themeColors,
  ...accentColorOverrides,
};

const darkPalette: Partial<IPalette> = {
  neutralDark: NeutralColors.gray20,
  neutralPrimary: NeutralColors.gray30,
  neutralPrimaryAlt: NeutralColors.gray40,
  neutralSecondary: NeutralColors.gray50,
  neutralSecondaryAlt: NeutralColors.gray90,
  neutralTertiary: NeutralColors.gray100,
  neutralTertiaryAlt: NeutralColors.gray110,
  neutralQuaternary: NeutralColors.gray130,
  neutralQuaternaryAlt: NeutralColors.gray150,
  neutralLight: NeutralColors.gray160,
  neutralLighter: NeutralColors.gray170,
  neutralLighterAlt: NeutralColors.gray220,
  white: NeutralColors.black,
  black: NeutralColors.white,
  ...darkThemeColors,
  ...darkAccentColorOverrides,
};

const theme = createTheme({ palette: defaultPalette });

const fonts: ITheme['fonts'] = merge({}, theme.fonts, getBaseThemeFontSizeStyles());

export const defaultTheme: ITheme = {
  ...theme,
  name: 'yammerDefault',
  fonts,
  semanticColors: {
    ...theme.semanticColors,
    ...getSemanticColorOverridesFromPalette(theme.palette),
  },
};

const darkTheme = createTheme({
  palette: darkPalette,
  isInverted: true,
});

export const defaultDarkTheme: ITheme = {
  ...darkTheme,
  name: 'yammerDefaultDark',
  fonts,
  semanticColors: {
    ...darkTheme.semanticColors,
    ...getSemanticColorOverridesFromPalette(darkTheme.palette, darkTheme.isInverted),
  },
};
