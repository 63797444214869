import {
  canAccessCampaignsDashboard,
  canAccessCampaignsInsights,
  canAccessCampaignsRecommendations,
  canCreateCampaigns,
  canDeleteCampaign,
  canEditCampaign,
  canEndCampaign,
  canManageCampaignOrganizers,
  canPublishCampaign,
  isCampaignAccessSettingsPending,
  isCampaignLandingPageInaccessible,
  isCampaignSashEnabled,
  isCampaignsCoOrganizersEnabled,
  isCampaignsExecutiveSponsorRefinementEnabled,
  isCampaignsExecutiveSponsorshipEnabled,
  isCampaignsHubEnabled,
  isMTOCampaignsEnabled,
} from '@ms/yammer-data/dist/domains/campaignRights/selectors';
import { Campaign } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';

type UseIsCampaignsHubEnabled = () => ReturnType<typeof isCampaignsHubEnabled>;
export const useIsCampaignsHubEnabled: UseIsCampaignsHubEnabled = () => useSelector(isCampaignsHubEnabled);

type UseIsMTOCampaignsEnabled = () => ReturnType<typeof isMTOCampaignsEnabled>;
export const useIsMTOCampaignsEnabled: UseIsMTOCampaignsEnabled = () => useSelector(isMTOCampaignsEnabled);

type UseIsCampaignsCoOrganizersEnabled = () => ReturnType<typeof isCampaignsCoOrganizersEnabled>;
export const useIsCampaignsCoOrganizersEnabled: UseIsCampaignsCoOrganizersEnabled = () =>
  useSelector(isCampaignsCoOrganizersEnabled);

type UseIsCampaignsExecutiveSponsorRefinementEnabled = () => ReturnType<
  typeof isCampaignsExecutiveSponsorRefinementEnabled
>;
export const useIsCampaignsExecutiveSponsorRefinementEnabled: UseIsCampaignsExecutiveSponsorRefinementEnabled = () =>
  useSelector(isCampaignsExecutiveSponsorRefinementEnabled);

type UseIsCampaignsExecutiveSponsorshipEnabled = () => ReturnType<typeof isCampaignsExecutiveSponsorshipEnabled>;
export const useIsCampaignsExecutiveSponsorshipEnabled: UseIsCampaignsExecutiveSponsorshipEnabled = () =>
  useSelector(isCampaignsExecutiveSponsorshipEnabled);

type UseIsCampaignSashEnabled = () => ReturnType<typeof isCampaignSashEnabled>;
export const useIsCampaignSashEnabled: UseIsCampaignSashEnabled = () => useSelector(isCampaignSashEnabled);

type UseIsCampaignAccessSettingsPending = () => ReturnType<typeof isCampaignAccessSettingsPending>;
export const useIsCampaignAccessSettingsPending: UseIsCampaignAccessSettingsPending = () =>
  useSelector(isCampaignAccessSettingsPending);

type UseCanAccessCampaignsDashboard = () => ReturnType<typeof canAccessCampaignsDashboard>;
export const useCanAccessCampaignsDashboard: UseCanAccessCampaignsDashboard = () =>
  useSelector(canAccessCampaignsDashboard);

type UseCanAccessCampaignsInsights = () => ReturnType<typeof canAccessCampaignsDashboard>;
export const useCanAccessCampaignsInsights: UseCanAccessCampaignsInsights = () =>
  useSelector(canAccessCampaignsInsights);

type UseCanAccessCampaignsRecommendations = () => ReturnType<typeof canAccessCampaignsRecommendations>;
export const useCanAccessCampaignsRecommendations: UseCanAccessCampaignsRecommendations = () =>
  useSelector(canAccessCampaignsRecommendations);

type UseCanCreateCampaigns = () => ReturnType<typeof canCreateCampaigns>;
export const useCanCreateCampaigns: UseCanCreateCampaigns = () => useSelector(canCreateCampaigns);

type UseIsCampaignLandingPageInaccessible = (
  campaignId: Campaign['id']
) => ReturnType<typeof isCampaignLandingPageInaccessible>;
export const useIsCampaignLandingPageInaccessible: UseIsCampaignLandingPageInaccessible = (campaignId) =>
  useSelector((state) => isCampaignLandingPageInaccessible(state, campaignId));

type UseCanEditCampaign = (campaignId: Campaign['id']) => ReturnType<typeof canEditCampaign>;
export const useCanEditCampaign: UseCanEditCampaign = (campaignId) =>
  useSelector((state) => canEditCampaign(state, campaignId));

type UseCanManageCampaignOrganizers = (campaignId: Campaign['id']) => ReturnType<typeof canManageCampaignOrganizers>;
export const useCanManageCampaignOrganizers: UseCanManageCampaignOrganizers = (campaignId) =>
  useSelector((state) => canManageCampaignOrganizers(state, campaignId));

type UseCanDeleteCampaign = (campaignId: Campaign['id']) => ReturnType<typeof canDeleteCampaign>;
export const useCanDeleteCampaign: UseCanDeleteCampaign = (campaignId) =>
  useSelector((state) => canDeleteCampaign(state, campaignId));

type UseCanPublishCampaign = (campaignId: Campaign['id']) => ReturnType<typeof canPublishCampaign>;
export const useCanPublishCampaign: UseCanPublishCampaign = (campaignId) =>
  useSelector((state) => canPublishCampaign(state, campaignId));

type UseCanEndCampaign = (campaignId: Campaign['id']) => ReturnType<typeof canEndCampaign>;
export const useCanEndCampaign: UseCanEndCampaign = (campaignId) =>
  useSelector((state) => canEndCampaign(state, campaignId));
